@import '../abstracts/variables';

.order-info-item {
  display: flex;
  margin-bottom: $spacer-9;

  svg {
    margin-right: $spacer-4;
  }
}
