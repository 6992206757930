@import '../abstracts/variables';

.layout-mobile {
  padding-top: $spacer-6;

  &__header {
    margin-bottom: $spacer-8;

    display: flex;
    align-items: center;

    & + .layout-mobile__sub-header {
      margin-bottom: $spacer-5;
    }
  }

  &__sub-header {
    margin-bottom: $spacer-8;
    font-size: $fontSize-xxxs;
    text-align: center;
  }

  &__header-icon {
    float: left;

    + .layout-mobile__header-text {
      margin-right: $spacer-11;
    }
  }

  &__header-text {
    font-weight: bold;
    font-size: $fontSize-xs;
    line-height: $fontSize-sm;
    text-align: center;

    flex: 1;
  }
}

.layout-two-columns {
  @media (min-width: $screen-xs) {
    margin-top: $spacer-9;

    &__slot-right {
      margin-top: $spacer-9;
    }
  }
}
