@import '../abstracts/variables';

.blocking-message__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: $spacer-5;
  padding-bottom: $spacer-5;
  line-height: 18px;

  .link,
  .link:active,
  .link:hover,
  .link:focus {
    @extend .link;
    font-size: $fontSize-xxs;
  }
}
