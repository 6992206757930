@import '../abstracts/variables';

.modal-content {
  &_mobile {
    .ant-drawer-content {
      max-height: 100vh;
      border-top-left-radius: $border-radius-modal;
      border-top-right-radius: $border-radius-modal;
    }

    .ant-drawer-body {
      padding-bottom: $spacer-9;
    }
  }

  &_desktop {
    .ant-modal-content {
      border-radius: $border-radius-modal;
    }
  }

  .store-picker-list {
    max-height: 220px;
    overflow-y: auto;
  }

  &__header {
    font-weight: bold;
    font-size: $fontSize-xs;
    line-height: $fontSize-sm;
    text-align: center;
    margin-bottom: $spacer-8;
  }

  &__header + .modal-content__subheader {
    margin-top: -$spacer-6;
  }

  &__subheader {
    margin-bottom: $spacer-8;
    font-size: $fontSize-xxxs;
    line-height: $fontSize-sm;
    text-align: center;
  }
}
