@import '../abstracts/variables';

body {
  font-family: $fontFamily-default;
  overflow-x: hidden;
}

#root {
  height: 100vh;
  background-color: $color-layout;
}
