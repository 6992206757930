@import '../abstracts/variables';

$list-times-item-height: 46px;
$list-times-height: calc(#{$list-times-item-height} * 5);

.list-times {
  font-weight: bold;
  max-height: $list-times-height;

  overflow-y: scroll;
  overflow-x: hidden;

  &__item {
    cursor: pointer;

    &:hover {
      background: rgba(238, 238, 238, 0.6);
    }

    &_active {
      background: $color-grayLight;
    }
  }
}
