@import '../abstracts/variables';

.header-menu {
  &_mobile {
    &.ant-menu-vertical {
      margin-top: $spacer-8;

      .ant-menu-item,
      .ant-menu-item-selected,
      .ant-menu-item-active,
      .ant-menu-submenu,
      .ant-menu-submenu-title {
        padding: 0;
        font-size: $fontSize-sm;
        margin: $spacer-2 0 $spacer-3 0 !important;
        font-weight: 700;
      }
    }

    .ant-menu-item.header-menu__link-item {
      height: auto !important;
      line-height: 22px;
      font-size: $fontSize-xxxs !important;
      margin: $spacer-2 0 !important;
    }
  }

  &.ant-menu {
    background: transparent;
    border: none !important;
  }

  .ant-menu-horizontal,
  .ant-menu-item,
  .ant-menu-item-selected,
  .ant-menu-item-active,
  .ant-menu-submenu,
  .ant-menu-submenu-title {
    color: $color-text-darkCherry !important;
    background: transparent !important;
    border: none !important;
    font-size: $fontSize-xs;
    padding-inline: $spacer-4;

    &::after {
      border: none !important;
    }
  }

  &__activilable-item {
    &.ant-menu-item-selected {
      color: $color-text-orange !important;
      font-weight: bold;
    }
  }

  &__link-item {
    &.ant-menu-item {
      text-decoration: underline;
    }

    &.ant-menu-item-selected {
      color: $color-text-orange !important;
      font-weight: bold;
    }
  }

  &__burger {
    float: left;
  }

  &__drawer {
    .ant-drawer-body {
      background: $color-main-y;
      padding-bottom: $spacer-5;
    }
  }
}
