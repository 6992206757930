@import '../abstracts/variables';

$suggestion-container-max-height: 200px;

.store-panel {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  background: $color-grayLight;

  &__container {
    max-width: $content-desktop-width;
    margin: 0 auto;
    padding: $spacer-4 $spacer-7 $spacer-3;
    cursor: pointer;
  }
}

// It made to change color for svg without adding new separate files for different status
.store {
  &_offline {
    filter: invert(25%) sepia(38%) saturate(5352%) hue-rotate(341deg) brightness(82%) contrast(98%);
  }

  &_online {
    filter: invert(46%) sepia(65%) saturate(360%) hue-rotate(58deg) brightness(98%) contrast(92%);
  }
}
