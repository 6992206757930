@import '../abstracts/variables';

.link,
.link:active,
.link:hover,
.link:focus {
  color: $color-main-orange;
  text-decoration: underline;
  font-size: $fontSize-xxxs;
  font-weight: bold;
}
