@import '../abstracts/variables';

$header-line-height: 28px;
$subheader-line-height: 21px;

.blocked-cookie {
  background: $color-main-y;
  width: 100%;
  min-height: 100%;
  padding: 0 $spacer-9;

  &__wrapper {
    margin: 110px auto;
    max-width: 325px;
    text-align: center;
  }

  &__header {
    color: $color-logo;
    font-size: $fontSize-lg;
    line-height: $header-line-height;
    font-weight: 700;
    margin-bottom: $spacer-8;
  }

  &__subheader {
    color: $special-red;
    font-size: $fontSize-sm;
    line-height: $subheader-line-height;
    margin-bottom: $spacer-8;
  }
}
