@import '../abstracts/variables';

.social-bar {
  &__separator {
    display: flex;
    align-items: center;
    text-align: center;
    color: $color-text;
    padding: $spacer-4 0;

    &:before {
      content: '';
      flex: 1;
      border-bottom: 1px solid $color-additionalGray;
      margin-right: $spacer-4;
    }

    &:after {
      content: '';
      flex: 1;
      border-bottom: 1px solid $color-additionalGray;
      margin-left: $spacer-4;
    }
  }

  &__google-btn {
    height: $button-default;
    border-radius: $border-radius;
    border: 1px solid $google-login-btn;
    color: $google-login-btn;
    position: relative;
    justify-content: center;
    background-color: $color-white;
    outline: none;
    cursor: pointer;
    &:hover,
    &:active,
    &:focus {
      @extend .social-bar__google-btn;
    }
  }
  &__facebook-btn {
    height: $button-default;
    border-radius: $border-radius;
    border: 1px solid $color-facebook;
    color: $color-white;
    position: relative;
    justify-content: center;
    background-color: $color-facebook;
    outline: none;
    cursor: pointer;
    &:hover,
    &:active,
    &:focus {
      @extend .social-bar__facebook-btn;
    }
  }

  &__icon {
    position: absolute;
    left: $spacer-3;
  }
}
