@import '../abstracts/variables';

.flex-noWrap {
  flex-wrap: nowrap;
}

.flex {
  &__item {
    &_1 {
      flex: 1;
    }
  }
}
