$chevron-height: 8px;
$collapse-border-radius: 14px;

.order-history-item {
  border-radius: $collapse-border-radius !important;
  background: $color-white;

  &__panel {
    border-radius: $collapse-border-radius !important;
  }

  &__chevron {
    transition: transform .24s;
    padding: 0 !important;
    position: absolute;
    right: $spacer-3 !important;
    top: calc(50% - #{$chevron-height}/2) !important;

    &_rotate--180 {
      transform: rotate(-180deg);
    }
  }
}

