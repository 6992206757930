$rate-store-star-size: 40px;

.rate-store-stars {
  display: flex;
  justify-content: space-between;

  .ant-rate-star {
    font-size: $rate-store-star-size;
  }
}
