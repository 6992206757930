
// Do not edit directly
// Generated on Wed, 30 Sep 2020 14:29:07 GMT

$color-main-y: #fbe122;
$color-main-b: #1a1c1d;
$color-main-orange: #ff8200;
$color-main-gray: #e1e1e1;
$color-main-w: #ffffff;
$color-mb-1: #623422;
$color-m-3: #fbe122;
$color-bg: #ffffff;
$color-black-high-emphasis: #000000;
$color-black-inactive: #000000;
$color-primary-500: #6202ee;
$color-black-medium-emphasis: #000000;
$color-secondary-700: #00696b;
$color-white-high-emphasis: #ffffff;
$color-surface-app-ui: #ffffff;
$color-surface-card: #fafafa;
$color-input-background: #e8e8e8;
$color-black-disabled: #000000;
$color-error: #ff0c3e;
$color-primary-700: #3700b3;
$color-gray-2: #4f4f4f;
$color-bck-1: #f6f6ff;
$color-text-2: #6a6a7f;
$color-text-1: #3e3e54;
$color-rich-black-default: #1b1b25;
$color-gray-1: #333333;
$color-sensitive-grey-dark: #e9e9ed;
$color-vibrant-cyan-default: #2dbecd;
$color-rich-black-lightest-2: #93939d;
$color-rich-red-default: #e61e50;
$color-border-color-base: #d9d9d9;
$color-text-color: #000000;
$color-primary-color: #1890ff;
$color-disabled-color: #000000;
$color-btn-primary-color: #ffffff;
$color-background-color-base: #f7f7f7;
$color-background: #000000;
$color-text-color-secondary: #000000;
$color-error-color: #f5222d;
$color-blue-1: #2f80ed;
$color-heading-color: #000000;
$color-normal-color: #d9d9d9;
$color-dividers: #000000;
$color-main-green: #ff8200;
$font-size-h-4-roboto-regular: 34;
$font-size-h-5-roboto-regular: 24;
$font-size-h-6-roboto-medium: 20;
$font-size-caption-roboto-regular: 12;
$font-size-subtitle-1-roboto-regular: 16;
$font-size-button-roboto-medium: 14;
$font-size-body-2-roboto-regular: 14;
$font-size-overline-roboto-medium: 10;
$font-size-body-1-roboto-regular: 18;
$font-size-font-size-base-14-px: 14;
$font-size-font-size-sm-12-px: 12;
$font-size-font-size-lg-font-size-base-2-px: 16;
$font-family-h-4-roboto-regular: Roboto, null;
$font-family-h-5-roboto-regular: Roboto, null;
$font-family-h-6-roboto-medium: Roboto, Roboto-Medium;
$font-family-caption-roboto-regular: Roboto, null;
$font-family-subtitle-1-roboto-regular: Roboto, null;
$font-family-button-roboto-medium: Roboto, Roboto-Medium;
$font-family-body-2-roboto-regular: Roboto, null;
$font-family-overline-roboto-medium: Roboto, Roboto-Medium;
$font-family-body-1-roboto-regular: Roboto, null;
$font-family-font-size-base-14-px: Inter, Inter-Regular;
$font-family-font-size-sm-12-px: Inter, Inter-Regular;
$font-family-font-size-lg-font-size-base-2-px: Inter, Inter-Regular;
$font-weight-h-4-roboto-regular: 400;
$font-weight-h-5-roboto-regular: 400;
$font-weight-h-6-roboto-medium: 500;
$font-weight-caption-roboto-regular: 400;
$font-weight-subtitle-1-roboto-regular: 400;
$font-weight-button-roboto-medium: 500;
$font-weight-body-2-roboto-regular: 400;
$font-weight-overline-roboto-medium: 500;
$font-weight-body-1-roboto-regular: 400;
$font-weight-font-size-base-14-px: 400;
$font-weight-font-size-sm-12-px: 400;
$font-weight-font-size-lg-font-size-base-2-px: 400;
$font-spacing-h-4-roboto-regular: 0.25px;
$font-spacing-h-5-roboto-regular: normal;
$font-spacing-h-6-roboto-medium: 0.15000000596046448px;
$font-spacing-caption-roboto-regular: 0.4000000059604645px;
$font-spacing-subtitle-1-roboto-regular: 0.15000000596046448px;
$font-spacing-button-roboto-medium: 0.75px;
$font-spacing-body-2-roboto-regular: 0.25px;
$font-spacing-overline-roboto-medium: 1.5px;
$font-spacing-body-1-roboto-regular: 0.5px;
$font-spacing-font-size-base-14-px: normal;
$font-spacing-font-size-sm-12-px: normal;
$font-spacing-font-size-lg-font-size-base-2-px: normal;
$font-lineheight-h-4-roboto-regular: 100%;
$font-lineheight-h-5-roboto-regular: 100%;
$font-lineheight-h-6-roboto-medium: 100%;
$font-lineheight-caption-roboto-regular: 113.77777862548828%;
$font-lineheight-subtitle-1-roboto-regular: 128%;
$font-lineheight-button-roboto-medium: 97.52381134033203%;
$font-lineheight-body-2-roboto-regular: 121.90476989746094%;
$font-lineheight-overline-roboto-medium: 136.5333251953125%;
$font-lineheight-body-1-roboto-regular: 132.74073791503906%;
$font-lineheight-font-size-base-14-px: 134.09524536132812%;
$font-lineheight-font-size-sm-12-px: 156.44444274902344%;
$font-lineheight-font-size-lg-font-size-base-2-px: 117.33332824707031%;
$effects-1: 0px 4px 16px rgba(0, 0, 0, 0.25);
$effects-2: 0px 4px 12px rgba(39, 43, 47, 0.11999999731779099);
$effects-1-dp-elevation: 0px 1px 3px rgba(0, 0, 0, 0.20000000298023224);
$effects-2-dp-elevation: 0px 1px 5px rgba(0, 0, 0, 0.20000000298023224);
$effects-shadow-2: 0px 4px 12px rgba(0, 0, 0, 0.15000000596046448);