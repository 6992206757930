@import '../abstracts/variables';

.radio {
  &-vertical {
    label {
      display: block;
      padding-bottom: $spacer-4;

      & + label {
        padding-top: $spacer-4;
      }
    }
  }
  &-horizontal {
    display: flex;
    justify-content: center;
  }
}
