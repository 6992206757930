@import '../abstracts/variables';

.header {
  background: $color-main-y;
  z-index: 20;
  width: 100%;
  height: $header-height;
  box-shadow: none;

  &__logo {
    height: $header-logo-height;
  }

  &__title {
    font-size: $fontSize-sm;
    font-weight: bold;
    color: $color-logo;

    &_with-p {
      padding-left: $spacer-4;
      padding-right: $spacer-4;
    }
  }

  &__desktop-logout-btn,
  &__desktop-logout-btn:hover {
    font-size: $fontSize-xxxs;
    font-weight: bold;
    color: $color-text-darkCherry !important;
    background: transparent !important;
    border: none;
    box-shadow: none;
  }
}
