@import '../abstracts/variables';

$menu-card-addons-height-max: 30vh;
$menu-card-height-min: 186px;
$menu-card-unavailable-addon-title-width: 122px;

.menu-card-sign {
  font-weight: bold;
  font-size: $fontSize-xxxs;
  line-height: $fontSize-sm;
  color: $color-text-darkCherry;
  margin-bottom: $spacer-4;
}

.menu-card-addons {
  max-height: $menu-card-addons-height-max;
  overflow-y: auto;
  overflow-x: hidden;

  &__unavailable-title {
    width: $menu-card-unavailable-addon-title-width;
    min-width: $menu-card-unavailable-addon-title-width;
  }
}

.menu-card {
  color: $color-text;
  border-radius: $border-radius-card;
  box-shadow: 0 4px 12px rgba(39, 43, 47, 0.12);
  padding: $spacer-4;

  &_box-shadow_orange {
    box-shadow: 0 4px 12px rgba(255, 130, 0, 0.35) !important;
  }

  &_border-light {
    box-shadow: none;
    border: 1px solid $border-color-default;
  }

  &_height_fixed {
    min-height: $menu-card-height-min;
  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & + .menu-card__footer {
      margin-top: $spacer-3;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
  }

  &__header {
    font-weight: bold;
    font-size: $fontSize-xs;
    line-height: $fontSize-sm;
    word-break: break-word;

    & + .menu-card__description {
      margin-top: $spacer-4;
    }
  }

  &__image-wrapper {
    flex-shrink: 0;
    width: $menu-item-img-width;
    margin-left: $spacer-4;
  }

  &__image {
    width: $menu-item-img-width;
    max-height: $menu-item-img-width;
  }

  &__description {
    word-break: break-word;
    font-size: $fontSize-xxxs;
    line-height: $fontSize-sm;
    opacity: $opacity-text;
  }
}
