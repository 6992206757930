@import '../abstracts/variables';

.basic {
  &__center-desktop-wrapper {
    max-width: $content-desktop-width;
    padding-left: $spacer-7;
    padding-right: $spacer-7;
    margin: 0 auto;
  }

  &__center-layout {
    width: 100%;
  }

  &__text_title {
    @extend .lh-sm;

    font-size: $fontSize-xs;
    font-weight: 700;
  }
  &__layout {
    width: 100%;
    height: calc(100% - #{$header-height});
    overflow-y: auto;
  }
}