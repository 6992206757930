@import '../figma/variables';

// Color variables
$color-white: #ffffff;
$color-whiteDark: #f4f4f4;
$color-whiteDarkest: #ededed;
$color-grayLightest: #f1f2f2;
$color-grayLight: #eee;
$color-gray: #999999;
$color-grayDark: #515151;
$color-blackLight: #333333;
$color-black: #1f1f1f;
$color-additionalGray: #a8a8a8;

$color-orange: #ff4938;
$color-orangeDark: #f03e2d;
$color-green: #83d14f;
$color-blue: #0289d6;
$color-facebook: #4267B2;

$color-layout: $color-bg;
$color-shadow: #00000026;

$color-logo: #583f35;
$color-whiteOpacity50: #ffffff80;

$color-text: #1a1d1e;
$color-text-darkCherry: #583f35;
$color-text-orange: $color-main-orange;

$color-input-bg: #f7f7ff;

$border-color-default: $color-grayLight;
$special-red: #CF232F;

$google-login-btn: rgba(0, 0, 0, 0.54);

$opacity-controls: 0.7;
$opacity-text: 0.8;
$opacity-text-md: 0.75;

// Screen size variables
$screen-xs: 426px;
$screen-sm: 576px;
$screen-md-break: 767px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1600px;

// Spaces
$spacer: 8px;

$spacer-0: $spacer * 0;
$spacer-1: $spacer / 4;
$spacer-2: $spacer / 2;
$spacer-3: $spacer;
$spacer-4: $spacer * 1.5;
$spacer-5: $spacer * 2;
$spacer-6: $spacer * 2.5;
$spacer-7: $spacer * 3;
$spacer-8: $spacer * 3.5;
$spacer-9: $spacer * 4;
$spacer-10: $spacer * 4.5;
$spacer-11: $spacer * 5;
$spacer-12: $spacer * 5.5;
$spacer-13: $spacer * 6;

// Font family variables
$fontFamily-default: 'Uniform Rounded', sans-serif;
$fontFamily-light: 'Uniform Rounded Light', sans-serif;
$fontFamily-bold: 'Uniform Rounded Bold', sans-serif;

// Font size variables
$fontSize-xxxs: 12px;
$fontSize-xxs: 14px;
$fontSize-xs: 16px;
$fontSize-sm: 18px;
$fontSize-md: 20px;
$fontSize-lg: 24px;
$fontSize-xlg: 26px;
$fontSize-xxlg: 36px;

// Header
$header-height: 60px;
$header-logo-height: 30px;

$content-desktop-width: 1078px;

$btn-opacity-height: 36px;

// Font weight
$fontWeight-xxl: 900;

// Modal window
$modalWidth: 350px;

// Border radius
$border-radius-btn-opacity: 4px;
$border-radius: 6px;
$border-radius-content: 8px;
$border-radius-card: 10px;
$border-radius-modal: 24px;

// Antd button height
$button-default: 46px;

// Header
$logo-img-sm: 48px;
$logo-img-xs: 32px;

// Menu item
$menu-item-img-width: 110px;
