@font-face {
  font-family: 'Uniform Rounded';
  src: url('../../fonts/Uniform Rounded.otf')
  format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Uniform Rounded';
  src: url('../../fonts/Uniform Rounded Bold.otf')
  format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'Uniform Rounded';
  src: url('../../fonts/Uniform Rounded Light.otf')
  format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Uniform Rounded Bold';
  src: url('../../fonts/Uniform Rounded Bold.otf')
  format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'Uniform Rounded Light';
  src: url('../../fonts/Uniform Rounded Light.otf')
  format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}


